import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-md);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;
      transform: translate(-4px, -4px);

      &:after {
        transform: translate(8px, 8px);
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 14px;
      left: 14px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    '产品管理',
    '产品增长',
    '一点前端技术',
    '一点点后端技术',
    'Node.js',
    'Prompt Engineering',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">关于</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              作为一个 91
              年出生的人，看上去这一代人是经历了一个黄金时代的生长，但成年离开学校后，却仅仅是看着黄金时代的尾巴越走越远。而幸运的是，在
              2000 年我拥有了第一台电脑——虽然运行的并不是 Windows 2000。在 2002 年通过家属院那种一首
              mp3 需要下载一下午的网速中，第一次接触到了互联网。
            </p>
            <p>
              让人欣慰的是，我并没有沉迷游戏，而是对技术产生了兴趣。在世纪初的闭塞而落后的中国北方的县城，我努力通过各种方式猎取身边能找到的各种技术类的资料——包括各种中小学「电脑课」教材、第二年才能买到的上一年的「电脑报」合订本。
            </p>

            <p>
              各种机缘下，我开始使用 FrontPage
              进行网站建设。从那时起，我就一直在网上活动，所做的事，基本上也都围绕互联网产品建设的一切。包括：产品建设、产品运营、内容创作、团队管理。
            </p>

            <p>
              作为一个好奇心爆棚，不仅喜欢产品设计，又喜欢写作，更喜欢折腾技术的人，我拥有多种技能。我喜欢把自己称为「全栈产品经理」。
            </p>

            <p>我有这些技能：</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
